
import { defineComponent, ref } from 'vue'
import HeaderHjpTable from '@/views/components/header/HeaderHjpTable.vue'
import HjpTabView from '@/views/components/tab/HjpTabView.vue'
import Card from 'primevue/card'
import $ from 'jquery'
import ActiveReport from './ActiveReport.vue'
import './verpal-report.scss'

export default defineComponent({
  name: 'VerpalReport',
  components: {
    HjpTabView,
    HeaderHjpTable,
    Card,
    ActiveReport
  },
  setup() {
    const activeTab = ref<number>(0)
    const searchValue = ref<string>('')
    const onSearch = (search: string) => {
      searchValue.value = search
    }
    const tabsChange = (val: number) => {
      searchValue.value = ''
      activeTab.value = val
      $('.input-search-header-custom').children('.input-primary').val('')
    }
    return {
      activeTab,
      onSearch,
      searchValue,
      tabsChange
    }
  }
})
