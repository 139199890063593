
import { PropType, computed, defineComponent, reactive, ref, getCurrentInstance } from 'vue'
import { IVerpalItem, IVerpal } from '@/utils/interfaces/iVerpal'
import moment from 'moment'
import Button from 'primevue/button'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import HjpCofirmModal from '@/views/components/modal/HjpConfirmationModal.vue'
import apiUseCase from '@/usecase/apiUseCase'
import { StatusVerpal } from '@/utils/enum/StatusVerpal'

export default defineComponent({
  name: 'ListVerpalReport',
  props: {
    data: {
      type: Object as PropType<IVerpalItem | null>,
      default: null
    }
  },
  components: {
    Button,
    HjpCofirmModal
  },
  setup(props) {
    const endpoint = 'transaction/v1/Verpal'
    const store = useStore()
    const router = useRouter()
    const dataItem = computed<IVerpalItem | null>(() => props.data)
    const app = getCurrentInstance()
    const {
      $toast, $strInd
    } = app!.appContext.config.globalProperties
    const selectedId = ref()

    const attrModalConfirmation = reactive({
      show: false,
      title: 'Approve Verpal?',
      subTitle: 'Pastikan verpal sudah sesuai dengan ketentuan dan peraturan yang berlaku pada Harapan Jaya!',
      btnSubmitTitle: 'Approve Verpal',
      imgContent: require('@/assets/img/modal-confirmation/appove-confirm.svg')
    })

    const onDetail = (verpal: IVerpal) => {
      router.push({
        name: 'verpal-report-detail',
        params: {
          id: verpal.Id
        }
      })
    }
    const approveAct = (id: any) => {
      // console.log('id', id)
      selectedId.value = id
      attrModalConfirmation.show = true
    }

    const hideDialogConfirmation = () => {
      attrModalConfirmation.show = false
    }

    const onSubmitConfirmation = async () => {
      store.dispatch('showLoading')
      try {
        const response = await apiUseCase.postOrPut(`${endpoint}/Approve`, selectedId.value, null)
        if (!response.error) {
          // store.dispatch('setShowModal', false)
          store.dispatch('setReloadDataList', true)
          $toast.add({
            severity: 'success',
            detail: 'Approve Verpal berhasil',
            group: 'bc',
            closable: false,
            life: 3000
          })
        } else {
          $toast.add({
            severity: 'error',
            detail: response.message,
            group: 'bc',
            closable: false,
            life: 3000
          })
        }
      } catch (error) {
        $toast.add({
          severity: 'error',
          detail: 'Approve Verpal gagal. Silahkan coba beberapa saat lagi',
          group: 'bc',
          closable: false,
          life: 3000
        })
      }
      hideDialogConfirmation()
      store.dispatch('hideLoading')
    }

    return {
      dataItem,
      moment,
      onDetail,
      approveAct,
      attrModalConfirmation,
      hideDialogConfirmation,
      onSubmitConfirmation,
      StatusVerpal
    }
  }
})
