import { StatusVerbal, StatusVerbalCode } from '@/utils/enum/StatusVerbal'
import colorStatusVerbal from '@/utils/helpers/colorStatusVerbal'

export default [{
  value: 13,
  label: StatusVerbal.Process,
  color: colorStatusVerbal(StatusVerbalCode.Process).color
},
{
  value: 14,
  label: StatusVerbal.Penalty,
  color: colorStatusVerbal(StatusVerbalCode.Penalty).color
},
{
  value: 15,
  label: StatusVerbal.Cancelled,
  color: colorStatusVerbal(StatusVerbalCode.Cancelled).color
},
{
  value: 16,
  label: StatusVerbal.Closed,
  color: colorStatusVerbal(StatusVerbalCode.Closed).color
}]
