
import Dialog from 'primevue/dialog'
import Card from 'primevue/card'
import Button from 'primevue/button'
import ModalDefault from '@/views/components/modal/ModalDefault.vue'
import SelectFetch from '@/views/components/form/SelectAsync.vue'
import HjpAlertModal from '@/views/components/modal/HjpAlertModal.vue'
import moment from 'moment'

import {
  defineComponent,
  computed,
  reactive,
  ref,
  toRefs,
  onMounted,
  getCurrentInstance
} from 'vue'
import apiUseCase from '@/usecase/apiUseCase'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'

const module = 'plotting'

export default defineComponent({
  components: {
    // Card,
    // Dialog,
    Button,
    SelectFetch,
    ModalDefault,
    HjpAlertModal
  },
  setup() {
    const x = ref()
    const app = getCurrentInstance()
    const {
      $icon,
      $toast
    } = app!.appContext.config.globalProperties
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const showModal = computed({
      get: () => store.state.headerButton.isShowDialogForm,
      set: (val) => {
        store.dispatch('setHeaderButtonActionShowDialogForm', val)
      }
    })
    const headerLabel = ref('Tambah Kondektur')
    const headerDescription = ref('Pilih kondektur yang akan di plotting untuk dimasukkan ke jadwal yang telah dibuat')
    const selectedKondektur = ref(null as any)
    const showErrorMessage = ref(false)
    const endpointTransactionPp = 'transaction/v1/TransactionPp'

    const modalConfirmation = reactive({
      show: false,
      imgContent: require('@/assets/img/modal-confirmation/delete-failed.svg'),
      title: 'Kondektur belum setor pendapatan',
      subTitle: 'Kondektur mempunyai setoran yang belum di approve oleh admin. Mohon cek kembali setoran kondektur lalu approve apabila kondektur sudah setor pendapatan',
      btnSubmitTitle: 'Kembali',
      subTitleHtml: false,
      isDelete: false
    })

    const hideConfirmDialog = () => {
      modalConfirmation.show = false
    }

    const closeDialog = () => {
      showModal.value = false
      console.log('closeDialog')
    }

    const setKondektur = () => {
      store.dispatch(`${module}/setSelectedConductor`, selectedKondektur.value)
      console.log('setPlottingKondektur', selectedKondektur.value)
      console.log('setPlottingKondektur', store.state)
      closeDialog()
      router.push({
        path: 'plotting-conductor-form'
      })
    }

    const onSubmit = async () => {
      store.dispatch('showLoading')
      try {
        const { result, error, message } = await apiUseCase.get(`${endpointTransactionPp}/Condectur-Transaction-Closed/${selectedKondektur.value.Id}`)
        if (!error) {
          if (result.TransactionPp.length < 2) {
            setKondektur()
          }
          modalConfirmation.show = true
        } else {
          $toast.add({
            severity: 'error',
            detail: message,
            group: 'bc',
            closable: false,
            life: 3000
          })
        }
      } catch (error) {
        console.log('err', error)
      }
      store.dispatch('hideLoading')
    }

    const showError = () => {
      if (selectedKondektur.value) {
        showErrorMessage.value = false
      } else {
        showErrorMessage.value = true
      }
    }

    onMounted(() => {
      if (route.path.includes('form')) {
        headerLabel.value = 'Ganti Kondektur'
      }
    })

    return {
      showModal,
      closeDialog,
      headerLabel,
      headerDescription,
      selectedKondektur,
      // setKondektur,
      showErrorMessage,
      showError,
      modalConfirmation,
      hideConfirmDialog,
      onSubmit
    }
  }
})
