
import {
  defineComponent,
  getCurrentInstance,
  watch,
  toRefs,
  reactive
} from 'vue'
import type { PropType } from 'vue'
import { useStore } from 'vuex'
import _ from 'lodash'
import {
  IVerpal,
  IVerpalItem
} from '@/utils/interfaces/iVerpal'
import HjpDataList from '@/views/components/list/HjpDataList.vue'
import ListVerpalReport from './components/ListVerpalReport.vue'

const endpoint = '/transaction/v1/Verpal'

export default defineComponent({
  name: 'ActiveReportVerpal',
  components: {
    HjpDataList,
    ListVerpalReport
  },
  props: {
    searchValue: {
      type: String,
      default: ''
    },
    tabIndex: {
      type: Number,
      default: 0
    },
    filterStatus: {
      type: Array as PropType<number[]>,
      default: () => []
    }
  },
  setup(props: any) {
    const app = getCurrentInstance()
    const {
      $toast
    } = app!.appContext.config.globalProperties
    const {
      searchValue,
      tabIndex,
      filterStatus
    } = toRefs(props)
    const store = useStore()
    const params = reactive({
      showHeader: false,
      endpoint,
      totalRow: 5,
      searchValue: searchValue.value,
      fieldSearch: ['query'],
      filters: [['statusVerpal', '=', filterStatus.value]]
    })
    const mapResponse = (items: IVerpal[]): IVerpalItem[] => {
      const manageData = _.chain(items).groupBy('ScheduleId').map((val: IVerpal[]) => {
        const CodeSchedule = val[0].TransactionPp.RosterCode
        const FirstRouteCityName = val[0].Rit.RouteName
        const Verpals = val
        return {
          CodeSchedule,
          FirstRouteCityName,
          Verpals
        }
      }).value()
      return manageData
    }
    watch(searchValue, (newValue: string) => {
      params.searchValue = newValue
      setTimeout(() => {
        store.dispatch('setReloadDataList')
      }, 100)
    })
    return {
      params,
      mapResponse
    }
  }
})
