import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ListVerpalReport = _resolveComponent("ListVerpalReport")!
  const _component_HjpDataList = _resolveComponent("HjpDataList")!

  return (_openBlock(), _createBlock(_component_HjpDataList, _normalizeProps(_guardReactiveProps(_ctx.params)), {
    content: _withCtx(({ dataItems }) => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mapResponse(dataItems), (data, idx) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "mb-10",
          key: idx
        }, [
          _createVNode(_component_ListVerpalReport, { data: data }, null, 8, ["data"])
        ]))
      }), 128))
    ]),
    _: 1
  }, 16))
}