
import {
  defineComponent,
  getCurrentInstance,
  onMounted,
  ref,
  reactive,
  computed,
  watch,
  onBeforeUnmount,
  onBeforeMount
} from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import apiUseCase from '@/usecase/apiUseCase'
import { ITrayek } from '@/utils/interfaces/iTrayek'
import returnUrlPrams from '@/utils/helpers/returnUrlPrams'
import moment from 'moment'
import { isEmpty } from 'lodash'
import Card from 'primevue/card'
import { PageState } from 'primevue/paginator'
import { plottingUseCase } from '@/usecase'
import InputIconRight from '@/views/components/form/InputIconRight.vue'
import SelectConductorForm from '@/views/pages/plotting-conductor/form/SelectKondektur.vue'
import Paginator from '@/views/components/paginator/Paginator.vue'
import Popper from 'vue3-popper'
import ConfirmModal from '@/views/components/modal/ConfirmModal2.vue'
import { IPlotting } from '@/utils/interfaces/iPlotting'
import EmptyState from '@/views/components/empty/State.vue'
import { IFilter } from '@/store/modules/plotting/model'
import HeaderFilter from './components/HeaderFilter.vue'
import LeftFilter from './components/LeftFilter.vue'
import TablePlotting from './components/TablePlotting.vue'
import InfoConductor from './components/InfoConductor.vue'

type TMapTrayek = ITrayek & { emptySubsCount: number }

const module = 'plotting'

export default defineComponent({
  name: 'PlottingConductor',
  components: {
    Card,
    InputIconRight,
    SelectConductorForm,
    HeaderFilter,
    LeftFilter,
    TablePlotting,
    Paginator,
    InfoConductor,
    Popper,
    ConfirmModal,
    EmptyState
  },
  setup() {
    const app = getCurrentInstance()
    const {
      $icon,
      $toast
    } = app!.appContext.config.globalProperties
    const icon = $icon
    const store = useStore()
    const endpointPlotting = '/roster/v1/Plotting'
    const endpointTrayek = '/management/v1/Trayek'
    const endpointTrayekSubs = '/management/v1/TrayekSubs'
    const selectedConductor = computed(() => store.getters[`${module}/selectedConductor`])
    const plottingList = ref()
    const selectedTrayek = ref()
    const placeholder = ref('Cari disini ...')
    const inputSearch = ref('')
    const filterDate = ref<Date>(moment().toDate())
    const trayekList = ref<TMapTrayek[]>([])
    const dataForm = ref()
    const refConfirmModal = ref<InstanceType<typeof ConfirmModal>>()
    const headerButton = ref({
      isShow: true,
      label: 'Tambah Kondektur',
    })

    const plottingTableProp = reactive({
      totalRecords: 0,
      isLoading: false,
      pageNumber: 1
    })

    const getPlottingDetail = async () => {
      plottingTableProp.isLoading = true
      try {
        const dateFilter = moment(filterDate.value.toDateString()).format('YYYY-MM-DD')
        const {
          result,
          error,
          count
        } = await apiUseCase.get(`${endpointPlotting}/ByTrayekId/${selectedTrayek.value}${returnUrlPrams({
          custom: [['plottingDate', '=', dateFilter]],
          pageNumber: plottingTableProp.pageNumber,
          filterField: ['regNumber'],
          search: inputSearch.value,
          pageSize: 10
        })}`)
        if (!error) {
          const data = result as IPlotting[]
          plottingList.value = data.map((v) => ({
            ...v,
            Pp: v.PpOrder,
            DepartureTime: v.DepartureTime
          }))
          plottingTableProp.totalRecords = count
        }
        plottingTableProp.isLoading = false
      } catch (error) {
        plottingTableProp.isLoading = false
      }
    }

    const choiceTrayek = (id: string) => {
      if (id && selectedTrayek.value !== id) {
        selectedTrayek.value = id
        getPlottingDetail()
      } else {
        selectedTrayek.value = ''
      }
    }

    const nextPagePlotting = (evt: PageState) => {
      plottingTableProp.pageNumber = evt.page + 1
      getPlottingDetail()
    }

    const onSearch = () => {
      plottingTableProp.pageNumber = 1
      getPlottingDetail()
    }

    watch(filterDate, (newVal) => {
      if (selectedTrayek.value) {
        getPlottingDetail()
      }
    })

    const paginate = reactive({
      pageSize: 10,
      pageNumber: 1,
      totalRecords: 0,
    })

    const filtersTrayek = reactive({
      custom: [] as any
    })

    const checkEmptyTrayekSubs = () => {
      const dateFilter = moment(filterDate.value.toDateString()).format('YYYY-MM-DD')
      trayekList.value.map(async (data) => {
        let count = 0
        const response = await apiUseCase.get(`${endpointTrayekSubs}/check-empty-trayek-subs${returnUrlPrams({
          custom: [['date', '=', dateFilter], ['trayekId', '=', data.Id]]
        })}`)
        if (!response.error) {
          const res = response.result
          count = res.TrayekSubsEmptyCount
        }
        data.emptySubsCount = count
      })
    }

    const getAllTrayek = async () => {
      store.dispatch('showLoading')
      const response = await apiUseCase.get(`${endpointTrayek}${returnUrlPrams({
        pageSize: paginate.pageSize,
        pageNumber: paginate.pageNumber,
        custom: [['activeOnly', '=', 'true'], ['trayekTypeId', '=', '8e072a19-599c-45f0-a337-08dbd378cce6'], ...filtersTrayek.custom]

      })}`)
      if (!response.error) {
        const result = response.result as ITrayek[]
        trayekList.value = result.map((v) => ({
          ...v,
          emptySubsCount: 0
        }))
        checkEmptyTrayekSubs()
        paginate.totalRecords = response.count
      }
      store.dispatch('hideLoading')
    }
    const listFilterTrayek = computed<IFilter[]>(() => store.getters[`${module}/filterTrayek`])
    watch(listFilterTrayek.value, (newVal) => {
      const mapResult: string[][] = newVal.map((v: IFilter) => ['name', '=', v.label])
      const findFilter: string[] = filtersTrayek.custom.filter((v: string[]) => v[0] !== 'name')
      filtersTrayek.custom = [...findFilter, ...mapResult]
      getAllTrayek()
    })

    const listFilterClass = computed<IFilter[]>(() => store.getters[`${module}/filterClass`])
    watch(listFilterClass.value, (newVal) => {
      const mapResult: string[][] = newVal.map((v: IFilter) => ['classId', '=', v.value])
      const findFilter: string[] = filtersTrayek.custom.filter((v: string[]) => v[0] !== 'classId')
      filtersTrayek.custom = [...findFilter, ...mapResult]
      getAllTrayek()
    })

    const listFilterTol = computed<IFilter[]>(() => store.getters[`${module}/filterTol`])
    watch(listFilterTol.value, (newVal) => {
      const mapResult: string[][] = newVal.map((v: IFilter) => ['isTol', '=', v.value])
      const findFilter: string[] = filtersTrayek.custom.filter((v: string[]) => v[0] !== 'isTol')
      filtersTrayek.custom = [...findFilter, ...mapResult]
      getAllTrayek()
    })

    const progressSubmitPlotting = async () => {
      store.dispatch('showLoading')
      try {
        const {
          error,
          message
        } = await plottingUseCase.editPlotting(dataForm.value)
        if (!error) {
          $toast.add({
            severity: 'success',
            detail: message,
            group: 'bc',
            closable: false,
            life: 3000
          })
          getPlottingDetail()
          checkEmptyTrayekSubs()
        } else {
          $toast.add({
            severity: 'error',
            detail: message,
            group: 'bc',
            closable: false,
            life: 3000
          })
        }
        store.dispatch('hideLoading')
      } catch (error) {
        getPlottingDetail()
        store.dispatch('hideLoading')
      }
    }

    const onSubmitPlotting = async (val: any) => {
      const dateFilter = moment(filterDate.value.toDateString()).format('YYYY-MM-DD')
      val.PlottingDate = dateFilter
      dataForm.value = val
      try {
        const { error, message, result } = await apiUseCase.get(`${endpointPlotting}/Condectur/${val.CondecturId}${returnUrlPrams({
          custom: [['plottingDate', '=', dateFilter]]
        })}`)
        if (!error) {
          let optionConfirm = {
            title: 'Apakah Anda Yakin Ingin Menyimpan Plotting?',
            subTitle: 'Pastikan data yang Anda masukkan  sudah benar sebelum tersimpan',
            btnSubmitTitle: 'Simpan',
            imgContent: require('@/assets/img/modal-confirmation/clean-edit-plotting.svg')
          }
          if (result.CondecturPlottingCount > 0) {
            optionConfirm = {
              ...optionConfirm,
              title: 'Apakah Anda Yakin Ingin Mengubah Plotting?',
              imgContent: require('@/assets/img/modal-confirmation/dirty-edit-plotting.svg')
            }
          }
          // eslint-disable-next-line no-unused-expressions
          refConfirmModal.value?.openModal(optionConfirm)
            .then((isConfirm) => {
              if (isConfirm) {
                progressSubmitPlotting()
              }
            })
        } else {
          $toast.add({
            severity: 'error',
            detail: message,
            group: 'bc',
            closable: false,
            life: 3000
          })
          getPlottingDetail()
        }
        store.dispatch('hideLoading')
      } catch (error) {
        getPlottingDetail()
        store.dispatch('hideLoading')
      }
    }

    const onChangePage = ({ page }: any) => {
      paginate.pageNumber = page + 1
      getAllTrayek()
    }

    onMounted(() => {
      store.dispatch('setHeaderButton', headerButton.value)
      getAllTrayek()
    })

    onBeforeMount(() => {
      store.dispatch(`${module}/setSelectedConductor`, null)
    })

    onBeforeUnmount(() => {
      store.dispatch(`${module}/flushPlottingFilters`)
    })

    return {
      getPlottingDetail,
      plottingList,
      moment,
      selectedTrayek,
      placeholder,
      inputSearch,
      icon,
      onSearch,
      store,
      filterDate,
      trayekList,
      listFilterTrayek,
      listFilterClass,
      module,
      onSubmitPlotting,
      paginate,
      onChangePage,
      selectedConductor,
      isEmpty,
      plottingTableProp,
      nextPagePlotting,
      listFilterTol,
      choiceTrayek,
      progressSubmitPlotting,
      refConfirmModal
    }
  }
})
