
import {
  ref, defineComponent
} from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'Reports',
  setup() {
    // const app = getCurrentInstance()
    // const { $toast } = app!.appContext.config.globalProperties
    const router = useRouter()
    const store = useStore()
    const data = ref([
      //   {
      //   judul: 'Excel Report',
      //   description: 'Laporan transaksi yang berbentuk excel yang dapat Anda download dan analisa secara manual',
      //   type: 'report',
      //   assets: require('@/assets/img/report-excel.png'),
      //   routeName: 'reporting-excel',
      // },
      {
        judul: 'Advanced Report',
        description: 'Laporan transaksi admin dan agent dalam bentuk yang lebih modern untuk memudahkan analisis Anda.',
        type: 'report',
        assets: require('@/assets/img/report-advance.png'),
        routeName: 'reports-advance',
      }
    ])
    const openReportType = (reportType: any) => {
      router.push({ name: reportType })
    }

    return {
      data,
      openReportType
    }
  }
})
