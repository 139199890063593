import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-verpal-report" }
const _hoisted_2 = { class: "header mb-4" }
const _hoisted_3 = { class: "cntnr-ctn" }
const _hoisted_4 = { class: "mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderHjpTable = _resolveComponent("HeaderHjpTable")!
  const _component_HjpTabView = _resolveComponent("HjpTabView")!
  const _component_ActiveReport = _resolveComponent("ActiveReport")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Card, { class: "card-no-shadow card-form" }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_HeaderHjpTable, {
            "title-header": "Verpal",
            showAddBtn: false,
            onOnSearch: _ctx.onSearch
          }, null, 8, ["onOnSearch"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_HjpTabView, {
            tabHeader: [
            { title: 'Laporan Aktif', value: 0 },
            { title: 'Riwayat Laporan', value: 1 }
          ],
            onTabsChange: _ctx.tabsChange
          }, null, 8, ["onTabsChange"]),
          _createElementVNode("div", _hoisted_4, [
            (_ctx.activeTab === 0)
              ? (_openBlock(), _createBlock(_component_ActiveReport, {
                  key: 0,
                  searchValue: _ctx.searchValue,
                  tabIndex: _ctx.activeTab,
                  filterStatus: [10]
                }, null, 8, ["searchValue", "tabIndex"]))
              : _createCommentVNode("", true),
            (_ctx.activeTab === 1)
              ? (_openBlock(), _createBlock(_component_ActiveReport, {
                  key: 1,
                  searchValue: _ctx.searchValue,
                  tabIndex: _ctx.activeTab,
                  filterStatus: [11]
                }, null, 8, ["searchValue", "tabIndex"]))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _: 1
    })
  ]))
}