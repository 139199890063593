
import apiUseCase from '@/usecase/apiUseCase'
import { IItemStatus, IVerbal, IVerbalFile } from '@/utils/interfaces/iVerbal'
import { computed, defineComponent, getCurrentInstance, onMounted, reactive, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import environment from '@/configs/environtment'
import getFileName from '@/utils/helpers/getFileName'
import { StatusVerbal, StatusVerbalCode } from '@/utils/enum/StatusVerbal'
import moment from 'moment'

// Components
import Card from 'primevue/card'
import InlineSvg from 'vue-inline-svg'
import SilentBoxSingle from '@/views/components/lightbox/SilentBoxSingle.vue'
import HjpCofirmModal from '@/views/components/modal/HjpConfirmationModal.vue'
import StatusVerbalChip from '@/views/components/chip/StatusVerbal.vue'
import Chip from '@/views/components/chip/Chip.vue'
import OverlayPanel from 'primevue/overlaypanel'
import HjpSideBar from '@/views/components/sidebar/HjpSideBar.vue'
import FineForm from '../form/FineForm.vue'

import ItemsStatus from '../data/listStatus'

const endpoint = '/transaction/v1/Verbal'
const routeNameBack = 'verbal-report'

export default defineComponent({
  name: 'VerbalDetail',
  components: {
    Card,
    InlineSvg,
    HjpCofirmModal,
    SilentBoxSingle,
    StatusVerbalChip,
    Chip,
    OverlayPanel,
    FineForm,
    HjpSideBar
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const dataDetail = ref<IVerbal>()
    const lightBox = ref()
    const app = getCurrentInstance()
    const refOpStatus = ref()
    // const isShowForm = ref<boolean>(store.state.hjpDataList.modalForm.data)
    const isShowForm = computed({
      get: () => store.state.hjpDataList.modalForm.show,
      set: (value) => {
        store.dispatch('setShowModalFormDl', value)
      }
    })
    const listStatus = ref<IItemStatus[]>(ItemsStatus)
    const {
      $toast
    } = app!.appContext.config.globalProperties

    const attrModalConfirmation = reactive({
      show: false,
      title: 'Approve Verpal?',
      subTitle: 'Apakah anda yakin ingin melakukan Approve Verpal?',
      btnSubmitTitle: 'Approve',
      imgContent: null
    })

    const getDetail = () => {
      store.dispatch('showLoading')
      apiUseCase.get(`${endpoint}/${route.params.id}`).then(({ error, result }) => {
        if (!error) {
          const mapData: IVerbal = {
            ...result,
            VerbalFiles: result.VerbalFiles.map((img: IVerbalFile) => ({
              ...img,
              filename: getFileName(img.FileName),
              src: img.FileName ? `${environment.getApiBaseUrl()}${img.FileName}` : '',
              alt: `verbal-${img.VerbalId}`
            }))
          }
          dataDetail.value = mapData
          store.dispatch('ui/setBreadcrumbs', [
            {
              title: 'Laporan Verbal',
              routeName: routeNameBack
            },
            {
              title: dataDetail.value?.Controller.Rit.TransactionPp.RosterCode,
              routeName: `${route.name as string}`
            }
          ])
        }
        store.dispatch('hideLoading')
      })
    }

    const showDropDown = (evt: Event) => {
      refOpStatus.value.toggle(evt)
    }

    const backToList = () => {
      router.push({
        name: 'verpal-report',
      })
    }

    const hideDialogConfirmation = () => {
      attrModalConfirmation.show = false
    }

    const showForm = () => {
      isShowForm.value = true
    }

    const processChangeStatus = (itemStatus: IItemStatus) => {
      refOpStatus.value.hide()
      if (itemStatus.label === StatusVerbal.Penalty) {
        isShowForm.value = true
      } else {
        store.dispatch('showLoading')
        apiUseCase.put(`${endpoint}/ChangeStatus/${dataDetail.value?.Id}`, {
          MsStatusId: itemStatus.value
        })
          .then(({ error, message }) => {
            if (!error) {
              $toast.add({
                severity: 'success',
                detail: 'Berhasil merubah status',
                group: 'bc',
                closable: false,
                life: 3000
              })
              getDetail()
            } else {
              $toast.add({
                severity: 'error',
                detail: message,
                group: 'bc',
                closable: false,
                life: 3000
              })
            }
          })
      }
    }

    const currencyNumberFormat = (val: any) => `Rp. ${Number(val).toLocaleString('id-ID')}`

    const reloadData = () => {
      isShowForm.value = false
      getDetail()
    }

    const showTicket = (dataId: any) => {
      const routeData = router.resolve({ name: 'ticket', params: { id: dataId } })
      window.open(routeData.href, '_blank')
    }

    onMounted(() => {
      getDetail()
    })
    return {
      dataDetail,
      lightBox,
      attrModalConfirmation,
      hideDialogConfirmation,
      showDropDown,
      refOpStatus,
      listStatus,
      processChangeStatus,
      isShowForm,
      reloadData,
      currencyNumberFormat,
      moment,
      showForm,
      StatusVerbal,
      showTicket,
      StatusVerbalCode
    }
  }
})
