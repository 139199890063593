import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { id: "list-delivery-item" }
const _hoisted_2 = { class: "sm:grid grid-cols-2 gap-2 lg:gap-6 md:gap-6" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "container-text" }
const _hoisted_5 = { class: "font-title" }
const _hoisted_6 = { class: "font-subTitle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "card-delivery cursor-pointer",
          key: index,
          onClick: ($event: any) => (_ctx.openReportType(item.routeName)),
          style: _normalizeStyle(`background-image: url('${item.assets}')`)
        }, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("p", _hoisted_5, _toDisplayString(item.judul), 1),
            _createElementVNode("p", _hoisted_6, _toDisplayString(item.description), 1)
          ])
        ], 12, _hoisted_3))
      }), 128))
    ])
  ]))
}